<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <div v-if="is_API_loaded || is_new_page" class="space-y-10">
          <section class="wrap">
            <div class="leftBlock">
              <!-- <img class="w-40 mt-4" src="@/assets/images/payments/ecpay_logo.png" alt="ecpay" /> -->
              <h2 class="h2 sm:mt-4">{{ setting.title }}</h2>
              <p>說明...</p>
            </div>
            <div class="rightBlock">
              <section>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">顯示的運送名稱 *</h4>
                  <p class="text-gray-400">※ 會於顧客結帳時顯示</p>
                </div>
                <ValidationProvider tag="div" class="w-full relative" name="顯示的送貨名稱" rules="required" v-slot="{ errors }">
                  <vs-input v-model.trim="setting.title" maxlength="30" placeholder="例：日本EMS快捷、台灣宅配、美國航空包裹"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </section>

              <section>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">運送國家/地區列表 *</h4>
                </div>
                <vs-select
                  @change="handleSelect"
                  class="mb-2"
                  placeholder="新增國家/地區"
                  filter
                  v-model="shit"
                  v-if="countries.length > 0"
                >
                  <vs-option
                    v-for="country in countries"
                    :key="country.en"
                    :label="country.cn + ' ' + country.en"
                    :value="country.cn + ' ' + country.en"
                    >{{ country.cn }} {{ country.en }}</vs-option
                  >
                </vs-select>
                <ul v-if="shitList.length" class="border border-gray-divide rounded-xl divide-y overflow-hidden">
                  <li
                    v-for="(item, index) in shitList"
                    :key="index"
                    class="flex items-center justify-between px-3 py-3 hover:bg-gray-background transition-colors"
                  >
                    <p>{{ item }}</p>
                    <vs-button @click="shitList.splice(index, 1)" transparent color="danger" size="mini">
                      <span class="text-sm">刪除</span>
                    </vs-button>
                  </li>
                </ul>
              </section>

              <section>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">運送說明</h4>
                  <p class="text-gray-400">※ 會於顧客結帳時顯示</p>
                </div>
                <!-- <ValidationProvider tag="div" class="w-full relative" name="顯示的送貨名稱" rules="required" v-slot="{ errors }"> -->
                <myTextarea v-model.trim="setting.note" maxlength="200" height="100px"></myTextarea>
                <!-- <errorDiv>{{ errors[0] }}</errorDiv> -->
                <!-- </ValidationProvider> -->
              </section>
            </div>
          </section>
          <hr />
          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">運費和細項</h2>
              <p>說明...</p>
            </div>
            <div class="rightBlock">
              <section>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">運費 *</h4>
                </div>
                <div class="flex items-center space-x-2">
                  <p>NT$</p>
                  <ValidationProvider tag="div" class="w-full relative" name="運費" rules="required|min_value:0" v-slot="{ errors }">
                    <vs-input type="number" v-model.number="setting.lpays"></vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </section>
              <section>
                <div class="flex items-center justify-between mb-2">
                  <h4 class="h4">溫層</h4>
                </div>
                <div class="flex items-center space-x-2">
                  <vs-radio success v-model="setting.temp" :val="1"> 常溫 </vs-radio>
                  <vs-radio success v-model="setting.temp" :val="2"> 冷藏 </vs-radio>
                  <vs-radio success v-model="setting.temp" :val="3"> 冷凍 </vs-radio>
                </div>
              </section>
              <section>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">貨到付款</h4>
                </div>
                <!-- <vs-checkbox v-model="setting.pterms"> 貨到付款 </vs-checkbox> -->
                <vs-switch success class="w-fit" v-model="setting.pterms" />
              </section>
            </div>
          </section>
          <hr />
          <div class="flex items-center" :class="[is_new_page ? 'justify-end' : 'justify-between']">
            <vs-button v-if="!is_new_page" size="large" color="danger" border ref="deleteBtn" @click="deleteDialog = true"
              >刪除運送方式</vs-button
            >

            <vs-button v-if="is_new_page" :disabled="invalid" size="large" ref="saveBtn" @click="saveLogistics(invalid, 'new')"
              >新增</vs-button
            >
            <vs-button v-else :disabled="invalid" size="large" ref="saveBtn" @click="saveLogistics(invalid, 'save')">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-4">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>

    <!-- 刪除 dialog  -->
    <vs-dialog overflow-hidden blur v-model="deleteDialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此運送方式？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的運送方式無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteDialog = false">取消</vs-button>
          <vs-button @click="deleteLogistics" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import countries from '@/assets/json/en_zh_country.json'
export default {
  name: 'shipping',
  data() {
    return {
      is_API_loaded: false,
      is_new_page: false,
      deleteDialog: false,
      countries: countries,
      shit: '',
      shitList: [],
      setting: {
        pterms: false,
        shit: '',
        land: '',
        title: '',
        temp: 1,
        lpays: '',
        note: '',
      },
    }
  },
  watch: {
    $route(to, from) {
      this.isNewFunction()
    },
  },
  created() {
    this.isNewFunction()
  },
  methods: {
    isNewFunction() {
      if (this.$route.name === 'new-shipping') {
        this.is_new_page = true
      } else {
        this.is_new_page = false
        this.loadSetting()
      }
    },
    // 4.4.2.2 物流(賣家宅配)資料-讀取
    loadSetting() {
      this.$axios({
        url: 'front/set/app/getLogisticsData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          slno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          console.log('物流(賣家宅配)資料-讀取', res.data.Data)

          let data = res.data.Data

          data.pterms ? (data.pterms = true) : false

          let shitAry = data.shit.split(',')

          this.shitList = shitAry

          shitAry.forEach((element) => {
            let cn = element.split(' ')[0] // 抓出英文名字
            this.countries = this.countries.filter((item) => item.cn !== cn) // 刪除符合的國家
          })

          this.setting = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.4.2.3 物流(賣家宅配)資料-新增
    // 4.4.2.4 物流(賣家宅配)資料-修改
    saveLogistics(invalid, status) {
      if (invalid) return
      if (!this.shitList.length) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '必須新增至少一個運送國家/地區', 'danger', 10000)
        return
      }

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''
      let data = {
        lang: 0,
        pterms: this.setting.pterms ? 6 : 0,
        shit: this.shitList.toString(),
        land: 0,
        title: this.setting.title,
        temp: this.setting.temp,
        lpays: this.setting.lpays,
        note: this.setting.note,
      }
      if (status === 'new') {
        url = 'front/set/app/setLogisticsData.php'
      } else if (status === 'save') {
        url = 'front/set/app/uLogisticsData.php'
        data.slno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          if (status === 'new') {
            this.$router.replace({
              name: 'edit-shipping',
              params: {
                id: res.data.Data.slno,
              },
            })
          } else if (status === 'save') {
            this.loadSetting()
          }
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.4.2.5 物流(賣家宅配)資料-刪除
    deleteLogistics() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/app/dLogisticsData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          slno: this.$route.params.id,
        },
      }).then((res) => {
        console.log('4.4.2.5 物流(賣家宅配)資料-刪除', res)
        loading.close()

        if (res.data.Success) {
          this.deleteDialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)

          setTimeout(() => {
            this.$router.replace({
              name: 'store_shipping',
            })
          }, 0)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 選擇國家地區後執行
    handleSelect(e) {
      // 判斷是不是字串，因若搜尋後選擇，也會回傳Event
      if (typeof e !== 'string' || e === '') return

      let cn = e.split(' ')[0] // 抓出中文名字

      setTimeout(() => {
        this.shitList.push(e)
        this.shit = ''
        this.countries = this.countries.filter((item) => item.cn !== cn) // 刪除符合的國家
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}
</style>
